var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"VWGVpjjxbc9VNi_bpWD_J"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
/*global window */

const Sentry = require('@sentry/nextjs');
const { ExtraErrorData } = require('@sentry/integrations');
const ConfigModule = require('@model/config/env-config');
const { EnvConfig } = ConfigModule;

const SENTRY_DSN = EnvConfig.get().SENTRY_DSN || EnvConfig.get().NEXT_PUBLIC_SENTRY_DSN;
const TRACE_SAMPLE_RATE = parseFloat(EnvConfig.get().TRACE_SAMPLE_RATE) || 0;
const ENVIRONMENT = EnvConfig.get().SENTRY_APP_ENV || 'dev';
const IS_LOCAL = EnvConfig.isLocal();
const IS_PROD = EnvConfig.isProd();

Sentry.init({
  dsn: SENTRY_DSN || 'https://c0461c4cbd1c443f8c9c4871188f38b7@o1012037.ingest.sentry.io/5977257',
  tracesSampleRate: TRACE_SAMPLE_RATE,
  environment: ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [EnvConfig.get().API_URL]
    }),
    new ExtraErrorData()
  ],
  enabled: !IS_LOCAL && !window.Cypress,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'ResizeObserver loop limit exceeded'
  ],
  beforeSend: (event, hint) => {
    try {
      const talkDeskMatcher = new RegExp('talkdesk', 'i');
      const talkdeskFileInStacktrace = !!event.exception.values[0].stacktrace.frames.find(({ filename }) =>
        talkDeskMatcher.test(filename)
      );
      if (talkdeskFileInStacktrace) {
        if (!IS_PROD) {
          console.log('Suppressing sentry error originating from Talkdesk script:', hint.originalException);
        }
        return null;
      }
    } catch (e) {
      return event;
    }
    return event;
  }
});
